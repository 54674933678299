import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import { AuthModule } from "@/store/modules/auth/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EncuestaModule from "@/store/modules/EncuestaModule";
import FamiliasModule from "@/store/modules/FamiliasModule";
import FormulariosModule from "@/store/modules/FormulariosModule";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import SolicitudExternaModule from "@/store/modules/SolicitudExternaModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FamiliasModule,
    FormulariosModule,
    ServiciosProgramadosModule,
    EncuestaModule,
    SolicitudExternaModule,
  },
});

export default store;
