import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";
import { SolicitudDatos } from "../models";

export interface FormularioInfo {
  pending: boolean;
  errors: unknown;
  codigo: string;
}
@Module({ namespaced: true, name: "SolicitudExternaModule" })
export default class SolicitudExternaModule
  extends VuexModule
  implements FormularioInfo
{
  pending = false;
  errors = {};
  codigo = "";
  entidadResponsable = "";

  get isPendingForm(): boolean {
    return this.pending;
  }

  get getCodigo(): string {
    return this.codigo;
  }

  get getError() {
    return this.errors;
  }

  datosSolicitud: SolicitudDatos | null = null;
  isLoading = false;

  @Mutation
  [Mutations.POST_SOLICITUD_EXTERNA_PENDING]() {
    this.pending = true;
  }

  @Mutation
  [Mutations.POST_SOLICITUD_EXTERNA_SUCCESS](data) {
    this.codigo = data.codigo;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_SOLICITUD_EXTERNA_FAILURE](errors) {
    this.errors = errors;
    this.pending = false;
  }

  @Action({ rawError: true })
  fetchSolicitudes({
    solicitudId,
    comarcaId,
  }: {
    solicitudId: string;
    comarcaId: string;
  }) {
    this.setLoad(true);

    const url = `/solicitud-informacion-externa/${solicitudId}/comarcaId/${comarcaId}/validar`;
    return ApiService.get(url, { params: { solicitudId, comarcaId } })
      .then(({ data }) => {
        this.setSolicitud(data);

        if (data.entidadResponsable) {
          this.setEntidadResponsable(data.entidadResponsable);
        }

        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            throw err.response.data;
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            const axiosError = { error: err };
            throw axiosError;
          }
        } else {
          const generalError = { error: err };
          throw generalError;
        }
      });
  }

  @Action({ rawError: true })
  [Actions.POST_SOLICITUD_EXTERNA]({
    datosSolicitud,
  }: {
    datosSolicitud: SolicitudDatos;
  }) {
    this.context.commit(Mutations.POST_SOLICITUD_EXTERNA_PENDING);

    const url = `/peticion-formularios-externos`;
    const params = {
      tipo: "SOLICITUD_EXTERNA",
      datosSolicitud: datosSolicitud,
      version: 1,
      comarcaId: datosSolicitud.comarcaId,
    };

    return ApiService.post(url, params)
      .then(({ data }) => {
        this.context.commit(Mutations.POST_SOLICITUD_EXTERNA_SUCCESS, data);
        return data.codigo;
      })
      .catch(({ response }) => {
        const error = response ? response.data.error : {};
        this.context.commit(Mutations.POST_SOLICITUD_EXTERNA_FAILURE, error);
        throw error;
      });
  }

  @Mutation
  setSolicitud(data) {
    this.datosSolicitud = data;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }

  @Mutation
  setEntidadResponsable(entidad: string) {
    this.entidadResponsable = entidad;
  }
}
