
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "KTFooter",
  components: {},
  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      i18n,
    };
  },
});
