import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Layout from "@/layout/Layout.vue";
import LayoutLogged from "@/layout/LayoutLogged.vue";
import { AuthModule } from "@/store/modules/auth";
import Error404 from "@/views/crafted/authentication/Error404.vue";
import Error500 from "@/views/crafted/authentication/Error500.vue";
import { getModule } from "vuex-module-decorators";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/encuesta",
        name: "encuesta",
        component: () => import("@/views/encuesta/Encuesta.vue"),
        props: (route) => ({
          servicioId: route.query.act,
          identificador: route.query.me,
        }),
      },
      {
        path: "/encuesta-contestada",
        name: "encuesta-contestada",
        component: () => import("@/views/encuesta/YaRespondido.vue"),
      },
      {
        path: "/profesionales",
        name: "profesionales",
        component: () => import("@/views/Profesionales.vue"),
      },
      {
        path: "/formulario-profesionales",
        name: "formulario-profesionales",
        component: () => import("@/views/FormularioProfesionales.vue"),
      },
      {
        path: "/familias",
        name: "familias",
        component: () => import("@/views/Familias.vue"),
        beforeEnter: (to, from, next) => {
          if (authModule.isAuthenticated) {
            return next({
              name: "formulario-familia-registrada",
              query: from.query,
            });
          }
          next();
        },
      },
      {
        path: "/servicios-colectivos",
        name: "servicios-colectivos",
        component: () => import("@/views/ServiciosColectivos.vue"),
      },
      {
        path: "/formulario-familias",
        name: "formulario-familias",
        component: () => import("@/views/FormularioFamilias.vue"),
      },
      {
        path: "/formulario-familias-paso-inicial",
        name: "formulario-familias-paso-inicial",
        component: () => import("@/views/FormularioFamiliasPasoInicial.vue"),
      },
      {
        path: "/formulario-familias-registradas",
        name: "formulario-familias-registradas",
        component: () => import("@/views/FormularioFamiliasRegistradas.vue"),
      },
      {
        path: "/formulario-familia-registrada",
        name: "formulario-familia-registrada",
        component: () => import("@/views/FormularioFamiliaLogeada.vue"),
      },
      {
        path: "/solicitud-informacion",
        name: "solicitud-informacion",
        component: () => import("@/views/solicitud/SolicitudDatos.vue"),
        props: (route) => ({
          formularioId: route.query.form,
          comarcaId: route.query.me,
          solicitudId: route.query.sol,
        }),
      },
      {
        path: "/solicitud-respondida",
        name: "solicitud-respondida",
        component: () => import("@/views/solicitud/RespuestaYaEnviada.vue"),
      },
      {
        path: "/formulario-actualizacion-profesionales",
        name: "formulario-actualizacion-profesionales",
        component: () =>
          import("@/views/FormularioProfesionalesActualizar.vue"),
      },
    ],
  },
  {
    path: "/",
    component: LayoutLogged,
    redirect: { name: "perfil" },
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/perfil",
        name: "perfil",
        redirect: { name: "informacion" },
        component: () => import("@/views/Perfil.vue"),
        children: [
          {
            path: "informacion",
            name: "informacion",
            component: () => import("@/views/perfil/Informacion.vue"),
          },
          {
            path: "menores",
            name: "menores",
            component: () => import("@/views/perfil/Menores.vue"),
          },
          {
            path: "servicios",
            name: "servicios",
            component: () => import("@/views/perfil/Servicios.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/acceso-familias",
        name: "acceso-familias",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        beforeEnter: (to, from, next) => {
          if (authModule.isAuthenticated) {
            return next({ name: "perfil" });
          }

          next();
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/reset-password/:token",
        name: "password-confirmation-token",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordConfirmation.vue"
          ),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: Error404,
  },
  {
    path: "/500",
    name: "500",
    component: Error500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const authModule = getModule(AuthModule, store);

router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.middleware == "auth") {
    authModule.verifyAuth();

    if (!authModule.isAuthenticated) {
      return {
        name: "acceso-familias",
      };
    }
  }

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
