
import FamiliaInfo from "@/components/FamiliaInfo.vue";
import KTBarraColores from "@/layout/header/BarraColores.vue";
import KTLogos from "@/layout/header/Logos.vue";
import { defineComponent } from "vue";

import {
asideDisplay,
headerLeft,
headerWidthFluid,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTLogos,
    KTBarraColores,
    FamiliaInfo,
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
